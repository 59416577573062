import React, { useEffect, useState } from 'react'
import { Alert, Grid2, styled } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { updateSettingsValidation } from '../../utils/validation'
import MInput from '../../components/material/MInput'
import MButton from '../../components/material/MButton'
import usePrinterStore from '../../store/usePrinterStore'
import { Container, HeaderContainer } from '../../styles/styles'
import MainHeader from '../../components/MainHeader'
import useSettingsStore from '../../store/useSettigsStore'

const FormContainer = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '300px',
}))

const ButtonContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
}))

const SettingsPage = () => {
  const { settings, updateSettings, getSettings } = useSettingsStore()
  const { printerStatuses, getPrinterStatus } = usePrinterStore()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { control, handleSubmit } = useForm({
    values: {
      printerIpAddress: settings?.printerIpAddress || '',
    },
    resolver: yupResolver(updateSettingsValidation),
    mode: 'all',
  })

  const onSubmit = async (data: { printerIpAddress: string }) => {
    await updateSettings(data).finally(async () => {
      await getPrinterStatus()
    })
  }

  useEffect(() => {
    if (!settings) {
      setIsLoading(true)
    }
    getSettings().finally(() => {
      setIsLoading(false)
    })
  }, [])

  return (
    <Container>
      <HeaderContainer container direction="row" justifyContent="space-between">
        <MainHeader title="Ustawienia" />
      </HeaderContainer>
      <Grid2 container direction="row" gap={4}>
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          {!isLoading && (
            <>
              <Controller
                name="printerIpAddress"
                control={control}
                render={({
                  field: { onChange, value },
                  formState: { errors },
                }) => (
                  <MInput
                    variant="outlined"
                    label="Adres IP drukarki"
                    type="text"
                    value={value}
                    onChange={onChange}
                    helperText={errors.printerIpAddress?.message}
                  />
                )}
              />
              <ButtonContainer>
                <MButton
                  style={{ width: '250px' }}
                  type="submit"
                  variant="contained"
                >
                  Zaktualizuj ustawienia
                </MButton>
              </ButtonContainer>
            </>
          )}
        </FormContainer>
        <Grid2 container direction="column" alignItems="flex-end" flex={1}>
          {printerStatuses?.printerStatusMessage ? (
            <Alert variant="outlined" severity="success">
              Drukarka aktywna. Adres drukarki: {settings?.printerIpAddress}
            </Alert>
          ) : (
            <Alert variant="outlined" severity="error">
              Drukarka nieaktywna. Sprawdź adres ip drukarki.
            </Alert>
          )}
          {printerStatuses?.printerStatusMessage &&
            !printerStatuses.paperStatusMessage && (
              <Alert variant="outlined" severity="warning">
                Uzupełnij etykiety.
              </Alert>
            )}
          {printerStatuses?.printerStatusMessage &&
            !printerStatuses.ribbonStatusMessage && (
              <Alert variant="outlined" severity="warning">
                Uzupełnij kalkę.
              </Alert>
            )}
        </Grid2>
      </Grid2>
    </Container>
  )
}

export default SettingsPage
