import React, { useEffect, useState } from 'react'
import { Container, HeaderContainer } from '../../styles/styles'
import MainHeader from '../../components/MainHeader'
import useProductionStore from '../../store/useProductionStore'
import DnDBoard from '../../components/DnD/DnDBoard'
import useSocket from '../../hooks/useSocket'
import { InputAdornment } from '@mui/material'
import MSearchField from '../../components/material/MSearchField'

const SandblastingBoardPage = () => {
  const [search, setSearch] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { sandblastingBoardColumns, getProductionBoardColumns } =
    useProductionStore()

  useSocket('sandblasting_ticket_created', () =>
    getProductionBoardColumns('sandblasting'),
  )
  useSocket('sandblasting_ticket_updated', () =>
    getProductionBoardColumns('sandblasting'),
  )

  useEffect(() => {
    if (!sandblastingBoardColumns.length) {
      setIsLoading(true)
    }
    getProductionBoardColumns('sandblasting').finally(() => {
      setIsLoading(false)
    })
  }, [])

  return (
    <Container>
      <HeaderContainer container direction="row" justifyContent="space-between">
        <MainHeader title="Piaskowanie" />
      </HeaderContainer>
      {!isLoading && (
        <>
          <MSearchField
            sx={{ '&: div': { backgroundColor: 'transparent' } }}
            type="number"
            variant="standard"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">RAL</InputAdornment>
                ),
              },
            }}
          />
          <DnDBoard
            type="sandblasting"
            items={sandblastingBoardColumns}
            search={`RAL${search}`}
            style={{ height: 'calc(100% - 112px)', overflowY: 'auto' }}
          />
        </>
      )}
    </Container>
  )
}

export default SandblastingBoardPage
