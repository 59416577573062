import React, { CSSProperties } from 'react'
import { Drawer, Grid2, styled } from '@mui/material'
import AssignmentIcon from '@mui/icons-material/Assignment'
import SettingsIcon from '@mui/icons-material/Settings'
import EngineeringIcon from '@mui/icons-material/Engineering'
import DescriptionIcon from '@mui/icons-material/Description'
import FactoryIcon from '@mui/icons-material/Factory'
import FormatPaintIcon from '@mui/icons-material/FormatPaint'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import PeopleIcon from '@mui/icons-material/People'
import GrainIcon from '@mui/icons-material/Grain'
import Logo from '../../assets/AM_HAMA_logo_250_50.png'
import { useLocation, useNavigate } from 'react-router-dom'
import MButton from '../material/MButton'
import useAuthStore from '../../store/useAuthStore'
import { filter, isNull, map } from 'lodash'
import useAppStore from '../../store/useAppStore'
import { MenuItem } from '../../types/navigation'
import NavItem from './NavItem'
import { NavigationList } from './styles'
import CollapsableNavItem from './CollapsableNavItem'
import { isAdmin } from '../../utils/roles'

const NavigationDrawer = styled(Drawer)(() => ({
  width: '200px',
  '& .MuiDrawer-paper': {
    backgroundColor: '#f2f8fc',
    color: '#000000',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}))

const Image = styled('img')(() => ({
  padding: '1rem 0',
  width: '180px',
  height: 'auto',
}))

const activeColor = '#0d539e'
const inactiveColor = '#000000'

export default function MainNavigation() {
  const navigate = useNavigate()
  const { isOpen } = useAppStore()
  const { logout, me } = useAuthStore()
  const location = useLocation()
  const isActive = (path: string) => {
    const currentPath = location.pathname
    return currentPath === path || currentPath.startsWith(`${path}/`)
  }
  const savedUserData = localStorage.getItem('loginData')

  const getIconStyle = (path: string): CSSProperties => {
    return { color: isActive(path) ? activeColor : inactiveColor }
  }

  const MenuItems: MenuItem[] = [
    {
      id: 'orders',
      name: 'Zlecenia',
      icon: <AssignmentIcon sx={getIconStyle('/')} />,
      path: '/',
    },
    {
      id: 'contractors',
      name: 'Kontrahenci',
      icon: <EngineeringIcon sx={getIconStyle('/contractors')} />,
      path: '/contractors',
    },
    {
      id: 'documents',
      name: 'Dokumenty',
      icon: <DescriptionIcon sx={getIconStyle('/documents')} />,
      path: '/documents',
    },
    {
      id: 'production',
      name: 'Produkcja',
      icon: <FactoryIcon sx={getIconStyle('/production')} />,
      path: '/production',
      subItems: [
        {
          id: 'sandblasting',
          name: 'Piaskowanie',
          icon: <GrainIcon sx={getIconStyle('/production/sandblasting')} />,
          path: '/production/sandblasting',
        },
        {
          id: 'painting',
          name: 'Malowanie',
          icon: <FormatPaintIcon sx={getIconStyle('/production/painting')} />,
          path: '/production/painting',
        },
      ],
    },
    {
      id: 'users',
      name: 'Użytkownicy',
      icon: <PeopleIcon sx={getIconStyle('/users')} />,
      path: '/users',
    },
    {
      id: 'account',
      name: 'Konto',
      icon: <AccountCircleIcon sx={getIconStyle('/account')} />,
      path: '/account',
    },
    {
      id: 'settings',
      name: 'Ustawienia',
      icon: <SettingsIcon sx={getIconStyle('/settings')} />,
      path: '/settings',
    },
  ]

  const filteredMenuItems = filter(MenuItems, (item) => {
    if (item.id === 'settings' && (!me || !isAdmin(me))) return false
    if (item.id === 'users' && (!me || !isAdmin(me))) return false

    return true
  })

  return (
    <NavigationDrawer variant="persistent" anchor="left" open={isOpen}>
      <Grid2 container direction="column" alignItems="center">
        <Image src={Logo} alt="AM HAMA Logo" />
        <NavigationList>
          {map(filteredMenuItems, (item, index) => {
            if (item.subItems) {
              return <CollapsableNavItem key={index} item={item} />
            } else {
              return <NavItem key={index} item={item} />
            }
          })}
        </NavigationList>
      </Grid2>
      {!isNull(savedUserData) && (
        <Grid2
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ marginBottom: '1rem' }}
          gap={1}
        >
          <MButton
            style={{ width: '150px' }}
            variant="contained"
            onClick={() => {
              logout()
              navigate('/login')
            }}
          >
            Wyloguj się
          </MButton>
          <p style={{ margin: 0, padding: 0 }}>Wersja: {APP_VERSION}</p>
        </Grid2>
      )}
    </NavigationDrawer>
  )
}
