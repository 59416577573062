import { User } from '../types/user'

export const isAdmin = (user: User) => {
  return user.role === 'admin'
}

export const isOffice = (user: User) => {
  return user.role === 'office'
}

export const isProduction = (user: User) => {
  return user.role === 'production'
}
