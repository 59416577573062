import React, { useEffect, useRef, useState } from 'react'
import useOrdersStore from '../../store/useOrdersStore'
import { Grid2 } from '@mui/material'
import PrintIcon from '@mui/icons-material/Print'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'
import MButton from '../../components/material/MButton'
import { filter, includes, isString, some, toLower } from 'lodash'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import ConfirmPrintDialog from '../../components/ConfirmPrintDialog'
import { Order } from '../../types/order'
import usePrinterStore from '../../store/usePrinterStore'
import MSearchField from '../../components/material/MSearchField'
import { Container, HeaderContainer, Icon } from '../../styles/styles'
import MainHeader from '../../components/MainHeader'
import useSocket from '../../hooks/useSocket'
import MDialog, { MDialogHandle } from '../../components/material/MDialog'
import { Column } from '../../types/customTableTypes'
import MTable from '../../components/material/MTable'

interface ExtendOrder extends Order {
  actions?: string
}

const OrdersPage = () => {
  const [search, setSearch] = useState<string>('')
  const [openConfirmPrintDialog, setOpenConfirmPrintDialog] =
    useState<boolean>(false)
  const [idToDelete, setIdToDelete] = useState<number | null>(null)
  const [elementToPrint, setElementToPrint] = useState<Order | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const dialogRef = useRef<MDialogHandle>(null)

  const navigate = useNavigate()

  const { printerStatuses } = usePrinterStore()
  const { orders, getOrders, deleteOrder } = useOrdersStore()

  useSocket('order_created', () => getOrders())
  useSocket('order_updated', () => getOrders())
  useSocket('order_deleted', () => getOrders())

  useEffect(() => {
    if (!orders.length) {
      setIsLoading(true)
    }
    getOrders().finally(() => {
      setIsLoading(false)
    })
  }, [])

  const filteredOrders = (items: Order[]) => {
    return filter(items, (item) =>
      some(
        item,
        (value) => isString(value) && includes(toLower(value), toLower(search)),
      ),
    )
  }

  const handleOpenDialog = (id: number | null) => {
    setIdToDelete(id)
    dialogRef.current?.changeDialogVisibility(true)
  }

  const confirmPrintDialogControl = (order: Order | null, isOpen: boolean) => {
    setElementToPrint(order)
    setOpenConfirmPrintDialog(isOpen)
  }

  const isPrinterConnected = () => {
    return (
      !printerStatuses?.printerStatusMessage ||
      !printerStatuses?.paperStatusMessage ||
      !printerStatuses?.ribbonStatusMessage
    )
  }

  const columns: Column<ExtendOrder>[] = [
    {
      key: 'id',
      name: 'Id',
      width: '50px',
      component: 'th',
      scope: 'row',
    },
    {
      key: 'name',
      name: 'Kontrahent',
      width: '30%',
      align: 'left',
    },
    {
      key: 'ralColor',
      name: 'RAL',
      align: 'left',
    },
    {
      key: 'date',
      name: 'Data przyjęcia',
      align: 'left',
      onRender: (item) => (
        <span>{dayjs(item.date).format('YYYY-MM-DD HH:ss')}</span>
      ),
    },
    {
      key: 'ciNumber',
      name: 'Numer PZ',
      align: 'left',
    },
    {
      key: 'actions',
      name: 'Actions',
      width: '128px',
      align: 'right',
      onRender: (item) => (
        <Grid2 container direction="row" justifyContent="flex-end">
          {!isPrinterConnected() && (
            <Icon onClick={() => confirmPrintDialogControl(item, true)}>
              <PrintIcon
                style={{
                  color: '#000000',
                }}
              />
            </Icon>
          )}
          <Icon onClick={() => navigate(`/orders/${item.id}`)}>
            <VisibilityIcon style={{ color: '#000000' }} />
          </Icon>
          <Icon onClick={() => navigate(`/orders/edit/${item.id}`)}>
            <EditIcon style={{ color: '#000000' }} />
          </Icon>
          <Icon onClick={() => handleOpenDialog(item.id)}>
            <DeleteIcon style={{ color: '#000000' }} />
          </Icon>
        </Grid2>
      ),
    },
  ]

  return (
    <Container>
      <HeaderContainer container direction="row" justifyContent="space-between">
        <MainHeader title="Zlecenia" />
        <Grid2 container direction="row" gap={4}>
          <MButton
            style={{ width: '50px' }}
            variant="contained"
            onClick={() => navigate('scan-qr')}
          >
            <QrCodeScannerIcon />
          </MButton>
          <MButton
            style={{ width: 'fit-content' }}
            startIcon={<AddIcon />}
            variant="contained"
            onClick={() => navigate('orders/add')}
          >
            Utwórz zlecenie
          </MButton>
        </Grid2>
      </HeaderContainer>
      <MSearchField
        sx={{ '&: div': { backgroundColor: 'transparent' } }}
        variant="standard"
        placeholder="Wyszukaj..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <MTable
        columns={columns}
        data={orders}
        isLoading={isLoading}
        customDataFilter={filteredOrders}
        style={{ maxHeight: 'calc(100dvh - 184px)', overflow: 'auto' }}
      />
      <ConfirmPrintDialog
        open={openConfirmPrintDialog}
        closeDialog={() => confirmPrintDialogControl(null, false)}
        dataToPrint={elementToPrint}
      />
      <MDialog
        ref={dialogRef}
        title="Usuwanie zlecenia"
        content="Czy na pewno chcesz usunąć to zlecenie?"
        onConfirm={() => {
          if (idToDelete) deleteOrder(idToDelete)
          setIdToDelete(null)
        }}
        onCancel={() => setIdToDelete(null)}
        cancelLabel="Anuluj"
        confirmLabel="Usuń"
      />
    </Container>
  )
}

export default OrdersPage
