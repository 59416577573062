import React from 'react'
import {
  Grid2,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useNavigate, useParams } from 'react-router-dom'
import useContractorsStore from '../../store/useContractorsStore'
import { find } from 'lodash'
import { Container, HeaderContainer } from '../../styles/styles'

const ContentContainer = styled(Grid2)(() => ({
  height: 'calc(100dvh - 88px)',
  overflowY: 'auto',
}))

const ContractorDetailPage = () => {
  const contractorId = useParams().contractorId
  const navigate = useNavigate()
  const { contractors } = useContractorsStore()

  const contractorDetails = find(
    contractors,
    (contractor) => contractor.id === Number(contractorId),
  )

  return (
    <Container>
      <HeaderContainer
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon style={{ color: '#000000' }} />
        </IconButton>
        <Typography variant="h5">
          Kontrahent: {contractorDetails?.name}
        </Typography>
      </HeaderContainer>
      <ContentContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell width="30%" variant="head">
                Imię Nazwisko / Nazwa firmy
              </TableCell>
              <TableCell>{contractorDetails?.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell width="30%" variant="head">
                NIP
              </TableCell>
              <TableCell>{contractorDetails?.taxId}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell width="30%" variant="head">
                Adres
              </TableCell>
              <TableCell>
                {contractorDetails?.postalCode} {contractorDetails?.city}{' '}
                {contractorDetails?.road}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell width="30%" variant="head">
                Telefon
              </TableCell>
              <TableCell>{contractorDetails?.phoneNumber}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </ContentContainer>
    </Container>
  )
}

export default ContractorDetailPage
