import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { addRejectionComment } from '../../utils/validation'
import { isNull } from 'lodash'
import MInput from '../material/MInput'
import { ProductionBoardRecord } from '../../types/production'

interface DnDRejectFormProps {
  setActiveItem: Dispatch<SetStateAction<ProductionBoardRecord | null>>
  activeItem: ProductionBoardRecord | null
}

const DndRejectForm = ({ activeItem, setActiveItem }: DnDRejectFormProps) => {
  const { control, watch } = useForm({
    defaultValues: {
      rejectedMessage: isNull(activeItem?.rejectedMessage)
        ? ''
        : activeItem.rejectedMessage,
    },
    resolver: yupResolver(addRejectionComment),
    mode: 'all',
  })

  const newRejectedMessage = watch('rejectedMessage')

  useEffect(() => {
    setActiveItem({
      ...activeItem,
      rejectedMessage: newRejectedMessage,
    } as ProductionBoardRecord)
  }, [newRejectedMessage])

  return (
    <Controller
      name="rejectedMessage"
      control={control}
      render={({ field: { value, onChange }, formState: { errors } }) => (
        <MInput
          fullWidth
          variant="outlined"
          label="Komentarz"
          type="text"
          value={value}
          onChange={onChange}
          helperText={errors.rejectedMessage?.message}
          multiline
          maxRows={4}
          minRows={4}
          style={{ height: '159px', marginTop: '0.5rem' }}
        />
      )}
    />
  )
}

export default DndRejectForm
