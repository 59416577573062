import React, { useEffect, useRef, useState } from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Grid2, styled, Tooltip } from '@mui/material'
import { ralColorsPalette } from '../../utils/ralColorsPalette'
import { ProductionBoardRecord } from '../../types/production'
import {
  translateFinishesValue,
  translateGlossLevelValue,
  translatePaintTypeValue,
  translateQuantityTypeValue,
} from '../../utils/helpers'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { capitalize } from 'lodash'
import { isAdmin } from '../../utils/roles'
import useAuthStore from '../../store/useAuthStore'

const CardWrapper = styled('div')(() => ({
  position: 'relative',
  backgroundColor: '#f2f8fc',
  borderRadius: '4px',
}))

const CardContainer = styled('div')(() => ({
  margin: '10px 0',
  opacity: 1,
  color: '#333',
  background: '#f2f8fc',
  padding: '10px',
  position: 'relative',
  touchAction: 'none',
  borderRadius: '4px',
}))

const CardTextPrimary = styled('p')(() => ({
  margin: '5px 0',
  padding: '0',
  fontSize: '16px',
}))

const CardTextSecondary = styled('p')(() => ({
  margin: '10px 0 5px 0',
  padding: '0',
  fontSize: '14px',
}))

type RALKeys = keyof typeof ralColorsPalette

interface DnDCardProps {
  item: ProductionBoardRecord
  type: 'painting' | 'sandblasting'
}

const DndCard = ({ item, type }: DnDCardProps) => {
  const itemId = String(item.id)
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: itemId,
    })

  const [openTooltip, setOpenTooltip] = useState<boolean>(false)
  const iconRef = useRef<SVGSVGElement | null>(null)

  const { me } = useAuthStore()

  const convertRalToHex = (ral: RALKeys) => {
    return ralColorsPalette[ral].HEX
  }

  const getSquareStyle = (ralColor: RALKeys, status: string) => {
    if (status === 'new') {
      return {
        width: '20px',
        height: '20px',
        border: `3px solid ${convertRalToHex(ralColor)}`,
        backgroundColor: 'transparent',
      }
    }

    if (status === 'painting') {
      return {
        width: '20px',
        height: '20px',
        border: `3px solid ${convertRalToHex(ralColor)}`,
        backgroundColor: `${convertRalToHex(ralColor)}`,
        clipPath: 'polygon(0 0, 100% 0, 0 100%)',
      }
    }

    return {
      width: '20px',
      height: '20px',
      border: `3px solid ${convertRalToHex(ralColor)}`,
      backgroundColor: convertRalToHex(ralColor),
    }
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (iconRef.current && !iconRef.current.contains(event.target as Node)) {
        setOpenTooltip(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <CardWrapper>
      {type === 'painting' && item.rejectedMessage && (
        <Tooltip title={item.rejectedMessage} open={openTooltip} arrow>
          <WarningAmberIcon
            ref={iconRef}
            onClick={() => setOpenTooltip(!openTooltip)}
            style={{
              color: '#ed6c02',
              position: 'absolute',
              right: '5px',
              top: '5px',
              zIndex: 1,
            }}
          />
        </Tooltip>
      )}
      <CardContainer
        ref={setNodeRef}
        {...attributes}
        {...listeners}
        style={{
          transform: CSS.Transform.toString(transform),
          transition,
        }}
        id={itemId}
      >
        <CardTextPrimary>{item.product}</CardTextPrimary>
        <CardTextPrimary>
          {item.quantity} {translateQuantityTypeValue(item.quantityType)}
        </CardTextPrimary>
        {item.sandblasting !== 'only' && (
          <>
            <Grid2
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <CardTextPrimary>{item.ralColor}</CardTextPrimary>
              <div
                style={getSquareStyle(
                  item.ralColor as RALKeys,
                  item.paintingStatus,
                )}
              ></div>
            </Grid2>
            <CardTextSecondary style={{ margin: 0, padding: 0 }}>
              {translatePaintTypeValue(item.paintType)}{' '}
              {translateGlossLevelValue(item.glossLevel)}
            </CardTextSecondary>
            <CardTextSecondary style={{ margin: 0, padding: 0 }}>
              {translateFinishesValue(item.finishing)}
            </CardTextSecondary>
            <CardTextSecondary style={{ margin: 0, padding: 0 }}>
              Podkład: {item.base === 'yes' ? 'tak' : 'nie'}
            </CardTextSecondary>
          </>
        )}
        <CardTextSecondary>{item.name}</CardTextSecondary>
        <CardTextSecondary>Zlecenie nr: {item.orderId}</CardTextSecondary>
        {me && isAdmin(me) && (
          <CardTextSecondary>
            Ostatnio modyfikowane przez: {capitalize(item.modifiedBy)}
          </CardTextSecondary>
        )}
      </CardContainer>
    </CardWrapper>
  )
}

export default DndCard
