import { AutoCompleteOption } from '../components/material/MAutocomplete'

export const glossLevelOptions: AutoCompleteOption[] = [
  { value: 'matt', label: 'Mat' },
  { value: 'semi-matt', label: 'Półmat' },
  { value: 'gloss', label: 'Połysk' },
  { value: 'semi-gloss', label: 'Półpołysk' },
]

export const finishesOptions: AutoCompleteOption[] = [
  { value: 'fine-structure', label: 'Struktura Drobna' },
  { value: 'coarse-structure', label: 'Struktura Gruba' },
  { value: 'smooth', label: 'Struktura Gładka' },
]

export const paintTypeOptions: AutoCompleteOption[] = [
  { value: 'polyester', label: 'Poliester' },
  { value: 'epoxy', label: 'Epoksyd' },
  { value: 'qualicoat', label: 'Qualicoat' },
  { value: 'industrial', label: 'Przemysłowa' },
]

export const quantityTypes: AutoCompleteOption[] = [
  { value: 'pieces', label: 'szt.' },
  { value: 'linearMeters', label: 'mb' },
  { value: 'squareMeters', label: 'm²' },
]

export const userRoleOptions: AutoCompleteOption[] = [
  { value: 'office', label: 'Biuro' },
  { value: 'production', label: 'Produkcja' },
]
