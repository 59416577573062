import React, { useEffect } from 'react'
import { styled } from '@mui/material'
import MainNavigation from './Navigation/MainNavigation'
import { Outlet } from 'react-router-dom'
import usePrinterStore from '../store/usePrinterStore'
import useAppStore from '../store/useAppStore'

const RootContainer = styled('div')(() => ({
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  minHeight: '100%',
  minWidth: '100%',
}))

const InnerContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isOpen',
})<{
  isOpen: boolean
}>(({ theme }) => ({
  minHeight: '100%',
  width: '100%',
  marginLeft: `-200px`,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ isOpen }) => isOpen,
      style: {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      },
    },
  ],
}))

const DefaultLayout = () => {
  const { getPrinterStatus, printerStatuses } = usePrinterStore()
  const { isOpen } = useAppStore()

  useEffect(() => {
    getPrinterStatus()

    let interval: number | undefined = undefined

    if (printerStatuses?.printerStatusMessage) {
      interval = setInterval(() => {
        getPrinterStatus()
      }, 15000)
    }

    return () => clearInterval(interval)
  }, [])

  return (
    <RootContainer>
      <MainNavigation />
      <InnerContainer isOpen={isOpen}>
        <Outlet />
      </InnerContainer>
    </RootContainer>
  )
}

export default DefaultLayout
