import React, { useEffect, useRef, useState } from 'react'
import { Grid2 } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import MButton from '../../components/material/MButton'
import useContractorsStore from '../../store/useContractorsStore'
import { filter, includes, isString, some, toLower } from 'lodash'
import VisibilityIcon from '@mui/icons-material/Visibility'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { useNavigate } from 'react-router-dom'
import MSearchField from '../../components/material/MSearchField'
import { Container, HeaderContainer, Icon } from '../../styles/styles'
import MainHeader from '../../components/MainHeader'
import useSocket from '../../hooks/useSocket'
import MDialog, { MDialogHandle } from '../../components/material/MDialog'
import { Contractor } from '../../types/contractor'
import { Column } from '../../types/customTableTypes'
import MTable from '../../components/material/MTable'

interface ExtendContractor extends Contractor {
  actions?: string
}

const ContractorsPage = () => {
  const [search, setSearch] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [idToDelete, setIdToDelete] = useState<number | null>(null)

  const dialogRef = useRef<MDialogHandle>(null)

  const navigate = useNavigate()

  const { contractors, getContractors, deleteContractor } =
    useContractorsStore()

  useSocket('contractor_added', () => getContractors())
  useSocket('contractor_updated', () => getContractors())
  useSocket('contractor_deleted', () => getContractors())

  const handleOpenDialog = (id: number | null) => {
    setIdToDelete(id)
    dialogRef.current?.changeDialogVisibility(true)
  }

  const filteredContractors = (items: Contractor[]) => {
    return filter(items, (item) =>
      some(
        item,
        (value) => isString(value) && includes(toLower(value), toLower(search)),
      ),
    )
  }

  useEffect(() => {
    if (!contractors.length) {
      setIsLoading(true)
    }
    getContractors().finally(() => {
      setIsLoading(false)
    })
  }, [])

  const columns: Column<ExtendContractor>[] = [
    {
      key: 'id',
      name: 'Id',
      width: '50px',
      component: 'th',
      scope: 'row',
    },
    {
      key: 'name',
      name: 'Nazwa',
      width: '30%',
      align: 'left',
    },
    {
      key: 'taxId',
      name: 'NIP',
      align: 'left',
    },
    {
      key: 'phoneNumber',
      name: 'Telefon kontaktowy',
      align: 'left',
    },
    {
      key: 'actions',
      name: 'Actions',
      width: '128px',
      align: 'right',
      onRender: (item) => (
        <Grid2 container direction="row" justifyContent="flex-end">
          <Icon onClick={() => navigate(`/contractors/${item.id}`)}>
            <VisibilityIcon style={{ color: '#000000' }} />
          </Icon>
          <Icon onClick={() => navigate(`/contractors/edit/${item.id}`)}>
            <EditIcon style={{ color: '#000000' }} />
          </Icon>
          <Icon onClick={() => handleOpenDialog(item.id)}>
            <DeleteIcon style={{ color: '#000000' }} />
          </Icon>
        </Grid2>
      ),
    },
  ]

  return (
    <Container>
      <HeaderContainer container direction="row" justifyContent="space-between">
        <MainHeader title="Kontrahenci" />
        <MButton
          style={{ width: 'fit-content' }}
          startIcon={<AddIcon />}
          variant="contained"
          onClick={() => navigate('/contractors/add')}
        >
          Dodaj kontrahenta
        </MButton>
      </HeaderContainer>
      <MSearchField
        sx={{ '&: div': { backgroundColor: 'transparent' } }}
        variant="standard"
        placeholder="Wyszukaj..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <MTable
        columns={columns}
        data={contractors}
        isLoading={isLoading}
        customDataFilter={filteredContractors}
        style={{ maxHeight: 'calc(100dvh - 184px)', overflow: 'auto' }}
      />
      <MDialog
        ref={dialogRef}
        title="Usuwanie kontrahenta"
        content="Czy na pewno chcesz usunąć tego kontrahenta?"
        onConfirm={() => {
          if (idToDelete) deleteContractor(idToDelete)
          setIdToDelete(null)
        }}
        onCancel={() => setIdToDelete(null)}
        cancelLabel="Anuluj"
        confirmLabel="Usuń"
      />
    </Container>
  )
}

export default ContractorsPage
