import React from 'react'
import { useBlocker } from 'react-router-dom'
import { FieldValues, UseFormReturn } from 'react-hook-form'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'

interface RouteGuardDialogProps<T extends FieldValues> {
  methods: UseFormReturn<T>
}

const RouteGuardDialog = <T extends FieldValues>({
  methods,
}: RouteGuardDialogProps<T>) => {
  const { formState } = methods

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return (
      formState.isDirty && currentLocation.pathname !== nextLocation.pathname
    )
  })

  return (
    <Dialog open={blocker.state === 'blocked'}>
      <DialogTitle>Masz niezapisane zmiany</DialogTitle>
      {blocker.state === 'blocked' && (
        <>
          <DialogContent style={{ padding: '1.25rem', width: '600px' }}>
            <div>
              <Typography variant="subtitle1">
                Czy na pewno chcesz opuścić tą stronę? Wszystkie zmiany zostaną
                utracone!
              </Typography>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => blocker.reset()}>Anuluj</Button>
            <Button onClick={() => blocker.proceed()}>Opuść</Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default RouteGuardDialog
