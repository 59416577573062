import React, { CSSProperties, useEffect, useState } from 'react'
import { Column } from '../../types/customTableTypes'
import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import { map, slice } from 'lodash'
import { useLocation, useNavigate } from 'react-router-dom'

interface MTableProps<T> {
  columns: Column<T>[]
  data: T[]
  isLoading: boolean
  customDataFilter: (items: T[]) => T[]
  style: CSSProperties
}

const Cell = styled(TableCell)(() => ({
  padding: '0.25rem 1rem',
}))

const MTable = <T,>({
  columns,
  isLoading,
  data,
  customDataFilter,
  style,
}: MTableProps<T>) => {
  const rowsPerPage = 10
  const navigate = useNavigate()
  const location = useLocation()
  const [page, setPage] = useState<number>(0)

  const getPageFromQuery = () => {
    const params = new URLSearchParams(location.search)
    return parseInt(params.get('page') || '0')
  }

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => {
    setPage(page)
    const params = new URLSearchParams(location.search)
    params.set('page', String(page))
    navigate(`?${params.toString()}`)
  }

  useEffect(() => {
    const page = getPageFromQuery()
    setPage(page)
  }, [location.search])

  return (
    <>
      <TableContainer style={style} component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {map(columns, (column, index) => (
                <TableCell
                  width={column.width}
                  key={index}
                  align={column.align}
                >
                  {column.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {!isLoading && (
            <TableBody>
              {map(
                slice(
                  customDataFilter(data),
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage,
                ),
                (record, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    style={{ height: '50px' }}
                  >
                    {map(columns, (column, index) => (
                      <Cell
                        component={column.component}
                        scope={column.scope}
                        key={index}
                      >
                        {column.onRender
                          ? column.onRender(record)
                          : String(record[column.key])}
                      </Cell>
                    ))}
                  </TableRow>
                ),
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[rowsPerPage]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
      />
    </>
  )
}

export default MTable
