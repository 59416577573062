import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import {
  filter,
  find,
  findIndex,
  flatMap,
  forEach,
  isNull,
  map,
  slice,
  some,
  sortBy,
  toLower,
  startsWith,
} from 'lodash'
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  rectIntersection,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import { Grid2 } from '@mui/material'
import DnDColumn from './DnDColumn'
import {
  ProductionBoardColumn,
  ProductionBoardRecord,
} from '../../types/production'
import useProductionStore from '../../store/useProductionStore'
import { canDrag } from '../../utils/dndHelpers'
import MDialog, { MDialogHandle } from '../material/MDialog'
import DndRejectForm from './DndRejectForm'
import DndCard from './DndCard'
import useAuthStore from '../../store/useAuthStore'

interface DnDBoardProps {
  items: ProductionBoardColumn[]
  type: 'painting' | 'sandblasting'
  search: string
  style: CSSProperties
}

const DnDBoard = ({ items, type, search, style }: DnDBoardProps) => {
  const [columns, setColumns] = useState<ProductionBoardColumn[]>([])
  const [activeColumnId, setActiveColumnId] = useState<string | null>(null)
  const [activeItem, setActiveItem] = useState<ProductionBoardRecord | null>(
    null,
  )

  const rejectDialogRef = useRef<MDialogHandle | null>(null)
  const confirmDialogRef = useRef<MDialogHandle | null>(null)

  const { updateProductionStatus } = useProductionStore()
  const { me } = useAuthStore()

  const filteredColumns = () => {
    const newColumns: ProductionBoardColumn[] = []
    forEach(columns, (column) => {
      const filteredItems = filter(column.items, (item) =>
        startsWith(toLower(item.ralColor), toLower(search)),
      )

      newColumns.push({
        ...column,
        items: filteredItems,
      })
    })

    return newColumns
  }

  useEffect(() => {
    setColumns(items)
  }, [items])

  const getStatusLabel = (status: string) => {
    switch (status) {
      case 'new':
        return 'Nowe'
      case 'painting':
        return 'Malowanie'
      case 'sandblasting':
        return 'Piaskowanie'
      case 'verify':
        return 'Weryfikacja'
      case 'rejected':
        return 'Odrzucone'
      case 'done':
        return 'Gotowe'
      default:
        return ''
    }
  }

  const findColumn = (unique: string | null) => {
    if (!unique) {
      return null
    }

    if (some(columns, (c) => c.id === unique)) {
      return find(columns, (c) => c.id === unique) ?? null
    }

    const id = String(unique)

    const itemWithColumnId = flatMap(columns, (c) => {
      const columnId = c.id
      return map(c.items, (i) => ({ itemId: i.id, columnId: columnId }))
    })

    const columnId = find(
      itemWithColumnId,
      (i) => String(i.itemId) === id,
    )?.columnId
    return find(columns, (c) => c.id === columnId) ?? null
  }

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event
    const activeId = String(active.id)

    const activeColumn = findColumn(activeId)

    if (!activeColumn) {
      return null
    }

    const activeItems = activeColumn.items

    const activeIndex = findIndex(
      activeItems,
      (item) => String(item.id) === activeId,
    )

    setActiveItem(activeItems[activeIndex])
    setActiveColumnId(activeColumn.id)

    return null
  }

  const handleDragOver = (event: DragOverEvent) => {
    const { active, over, delta } = event
    const activeId = String(active.id)
    const overId = over ? String(over.id) : null

    const originalColumn = findColumn(activeColumnId)
    const activeColumn = findColumn(activeId)
    const overColumn = findColumn(overId)

    if (
      !activeColumn ||
      !overColumn ||
      !originalColumn ||
      activeColumn === overColumn
    ) {
      return null
    }

    if (!canDrag(originalColumn.id, overColumn.id, me!.role)) {
      return null
    }

    const activeItems = activeColumn.items
    const overItems = overColumn.items

    const activeIndex = findIndex(
      activeItems,
      (item) => String(item.id) === activeId,
    )
    const overIndex = findIndex(overItems, (item) => String(item.id) === overId)

    setColumns((prevState) => {
      const newIndex = () => {
        const putOnBelowLastItem =
          overIndex === overItems.length - 1 && delta.y > 0
        const modifier = putOnBelowLastItem ? 1 : 0
        return overIndex >= 0 ? overIndex + modifier : overItems.length + 1
      }

      return map(prevState, (column) => {
        if (column.id === activeColumn.id) {
          return {
            ...column,
            items: filter(activeItems, (item) => String(item.id) !== activeId),
          }
        } else if (column.id === overColumn.id) {
          if (type === 'painting') {
            activeItems[activeIndex].paintingStatus = overColumn.id
          } else {
            activeItems[activeIndex].sandblastingStatus = overColumn.id
          }

          const updatedItems = [
            ...slice(overItems, 0, newIndex()),
            activeItems[activeIndex],
            ...slice(overItems, newIndex(), overItems.length),
          ]

          return {
            ...column,
            items: updatedItems.sort((a, b) => a.orderId - b.orderId),
          }
        } else {
          return column
        }
      })
    })

    return null
  }

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event
    const activeId = String(active.id)
    const overId = over ? String(over.id) : null

    const originalColumn = findColumn(activeColumnId)
    const activeColumn = findColumn(activeId)
    const overColumn = findColumn(overId)

    if (!activeColumn || !overColumn || !originalColumn) {
      return null
    }

    if (!canDrag(originalColumn.id, overColumn.id, me!.role)) {
      return null
    }

    const activeItems = activeColumn.items

    const activeIndex = findIndex(
      activeItems,
      (item) => String(item.id) === activeId,
    )

    if (originalColumn.id !== overColumn.id) {
      if (overColumn.id === 'rejected') {
        rejectDialogRef.current?.changeDialogVisibility(true)
      } else if (overColumn.id === 'done') {
        confirmDialogRef.current?.changeDialogVisibility(true)
      } else {
        await updateProductionStatus(type, activeItems[activeIndex])
      }
    }

    if (activeColumn.id !== overColumn.id) {
      const movedItem = activeItems[activeIndex]

      if (type === 'painting') {
        movedItem.paintingStatus = overColumn.id
      } else {
        movedItem.sandblastingStatus = overColumn.id
      }

      setColumns((prevState) => {
        return map(prevState, (column) => {
          if (column.id === activeColumn.id) {
            return {
              ...column,
              items: filter(column.items, (item) => item.id !== movedItem.id),
            }
          } else if (column.id === overColumn.id) {
            return {
              ...column,
              items: [
                ...column.items,
                {
                  ...movedItem,
                  orderId: activeColumn.items[activeIndex].orderId,
                },
              ],
            }
          }
          return column
        })
      })
    }

    setColumns((prevState) => {
      return map(prevState, (column) => ({
        ...column,
        items: sortBy(column.items, 'orderId'),
      }))
    })

    return null
  }

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={rectIntersection}
        onDragEnd={handleDragEnd}
        onDragOver={handleDragOver}
        onDragStart={handleDragStart}
      >
        <Grid2 container direction="row" gap={1} style={style}>
          {filteredColumns().map((column) => (
            <DnDColumn
              key={column.id}
              id={column.id}
              label={getStatusLabel(column.id)}
              items={column.items}
              type={type}
            ></DnDColumn>
          ))}
        </Grid2>
        <DragOverlay>
          {activeItem ? <DndCard type={type} item={activeItem} /> : null}
        </DragOverlay>
      </DndContext>
      <MDialog
        ref={rejectDialogRef}
        title="Zlecenie odrzucone"
        content={
          <DndRejectForm
            activeItem={activeItem}
            setActiveItem={setActiveItem}
          />
        }
        onConfirm={async () => {
          if (activeItem) {
            await updateProductionStatus(type, activeItem)
          }
        }}
        confirmLabel="Dodaj komentarz"
        disableConfirm={
          isNull(activeItem?.rejectedMessage) ||
          activeItem?.rejectedMessage.length === 0
        }
      />
      <MDialog
        ref={confirmDialogRef}
        title="Zlecenie zakończone"
        content="Od teraz zmiany statusów nie są możliwe."
        onConfirm={async () => {
          if (activeItem) {
            activeItem.paintingStatus = 'done'
            await updateProductionStatus(type, activeItem)
          }
        }}
        confirmLabel="Zatwierdź"
      />
    </>
  )
}

export default DnDBoard
