import { useLocation, useNavigate } from 'react-router-dom'
import usePrinterStore from '../../store/usePrinterStore'
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { isNull, isUndefined } from 'lodash'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import React, { CSSProperties } from 'react'
import { MenuItem } from '../../types/navigation'
import { ExpandLess, ExpandMore } from '@mui/icons-material'

interface NavItemProps {
  item: MenuItem
  onClick?: () => void
  isOpen?: boolean
  style?: CSSProperties
}

const activeColor = '#0d539e'

export default function NavItem({
  item,
  onClick,
  isOpen,
  style,
}: NavItemProps) {
  const navigate = useNavigate()
  const { printerStatuses } = usePrinterStore()
  const location = useLocation()
  const isActive = (path: string) => {
    const currentPath = location.pathname
    return currentPath === path || currentPath.startsWith(`${path}/`)
  }
  const savedUserData = localStorage.getItem('loginData')

  const shouldShowWarning = () => {
    return (
      !printerStatuses?.printerStatusMessage ||
      !printerStatuses?.paperStatusMessage ||
      !printerStatuses?.ribbonStatusMessage
    )
  }

  return (
    <ListItem disablePadding style={style}>
      <ListItemButton
        disabled={isNull(savedUserData)}
        onClick={() => {
          if (onClick) {
            onClick()
          } else {
            navigate(item.path)
          }
        }}
      >
        <ListItemIcon style={{ minWidth: '30px' }}>{item.icon}</ListItemIcon>
        <ListItemText
          style={isActive(item.path) ? { color: activeColor } : {}}
          primary={item.name}
        />
        {isUndefined(isOpen) ? null : isOpen ? <ExpandLess /> : <ExpandMore />}
        {item.id === 'settings' && shouldShowWarning() && (
          <WarningAmberIcon style={{ color: '#ed6c02' }} />
        )}
      </ListItemButton>
    </ListItem>
  )
}
