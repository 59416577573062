import { useEffect } from 'react'
import { socket } from '../App'

export default function useSocket<T>(
  eventName: string,
  eventHandler: (...args: T[]) => void,
) {
  useEffect(() => {
    socket.on(eventName, eventHandler)

    return () => {
      socket.off(eventName, eventHandler)
    }
  }, [])
}
