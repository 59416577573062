import { create } from 'zustand'
import { callApi } from '../utils/callApi'
import { User } from '../types/user'
import { NotificationService } from '../service/NotificationService'

interface Store {
  users: User[]
  getUsers: () => Promise<void>
  addUser: (data: object) => Promise<number>
  updateUser: (id: number, data: object) => Promise<number>
  deleteUser: (id: number) => void
}

const useUsersStore = create<Store>((set, get) => ({
  users: [],

  getUsers: async () => {
    await callApi<{ data: User[] }>('/api/users', 'GET').then((res) =>
      set(() => ({ users: res.data })),
    )
  },

  addUser: async (data) =>
    await callApi<{ data: { user: User; message: string }; status: number }>(
      '/api/users',
      'POST',
      data,
    )
      .then((res) => {
        NotificationService.showNotification(res.data.message, 'success')
        set((state) => ({ users: [...state.users, res.data.user] }))
        return res.status
      })
      .catch((error) => {
        NotificationService.showNotification(error.message, 'error')
        return error.status
      }),

  updateUser: async (id, data) =>
    await callApi<{ data: { user: User; message: string }; status: number }>(
      `/api/users/${id}`,
      'PUT',
      data,
    ).then((res) => {
      const index = get().users.findIndex((user) => user.id === id)
      if (index !== -1) {
        NotificationService.showNotification(res.data.message, 'success')
        set((state) => {
          const users = [...state.users]
          users[index] = res.data.user
          return { users }
        })
      }
      return res.status
    }),

  deleteUser: (id) => {
    callApi<{ data: { message: string } }>(`/api/users/${id}`, 'DELETE').then(
      (res) => {
        NotificationService.showNotification(res.data.message, 'success')

        return set((state) => ({
          users: state.users.filter((order) => order.id !== id),
        }))
      },
    )
  },
}))

export default useUsersStore
