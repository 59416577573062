import React, { useState } from 'react'
import { MenuItem } from '../../types/navigation'
import { NavigationList } from './styles'
import { Collapse } from '@mui/material'
import NavItem from './NavItem'
import { map } from 'lodash'

interface CollapsableNavItemProps {
  item: MenuItem
}

export default function CollapsableNavItem({ item }: CollapsableNavItemProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleOpen = () => setIsOpen(!isOpen)

  return (
    <>
      <NavItem item={item} onClick={handleOpen} isOpen={isOpen} />
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <NavigationList>
          {map(item.subItems, (subItem, index) => (
            <NavItem
              key={index}
              item={subItem}
              style={{ paddingLeft: '0.75rem' }}
            />
          ))}
        </NavigationList>
      </Collapse>
    </>
  )
}
