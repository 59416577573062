import React from 'react'
import { Grid2, IconButton, Typography } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import useAppStore from '../store/useAppStore'

interface MainHeaderProps {
  title: string
}

export default function MainHeader({ title }: MainHeaderProps) {
  const { isOpen, setIsOpen } = useAppStore()

  return (
    <Grid2 container direction="row" alignItems="center">
      <IconButton edge="start" onClick={setIsOpen}>
        {isOpen ? <MenuOpenIcon /> : <MenuIcon />}
      </IconButton>
      <Typography variant="h5">{title}</Typography>
    </Grid2>
  )
}
