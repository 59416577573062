import React, { useEffect, useState } from 'react'
import useAuthStore from '../store/useAuthStore'
import { isNull } from 'lodash'
import { Navigate } from 'react-router-dom'
import { LoginData } from '../types/auth'
import useSettingsStore from '../store/useSettigsStore'

interface ProtectedRouteProps {
  children: React.ReactNode
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const HOW_LONG_USER_SHOULD_BE_LOGGED = 24 * 1000 * 60 * 60
  const { me, login } = useAuthStore()
  const { getSettings } = useSettingsStore()
  const [isChecking, setIsChecking] = useState(true)

  useEffect(() => {
    const checkIsLoggedIn = async () => {
      const savedUserData = localStorage.getItem('loginData')
      if (savedUserData) {
        const savedUser: LoginData = JSON.parse(savedUserData)
        if (
          savedUser.isLogin &&
          Date.now() < savedUser.timestamp + HOW_LONG_USER_SHOULD_BE_LOGGED
        ) {
          await login({ login: savedUser.login, password: savedUser.password })
          await getSettings()
        } else {
          localStorage.removeItem('loginData')
        }
      }
      setIsChecking(false)
    }

    checkIsLoggedIn()
  }, [])

  if (!isChecking && isNull(me)) {
    return <Navigate to="/login" replace />
  }

  return children
}

export default ProtectedRoute
