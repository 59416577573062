import React from 'react'
import { Container, HeaderContainer } from '../../styles/styles'
import MainHeader from '../../components/MainHeader'
import { Grid2, styled } from '@mui/material'
import useAuthStore from '../../store/useAuthStore'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { updateCurrentUserValidation } from '../../utils/validation'
import MInput from '../../components/material/MInput'
import MButton from '../../components/material/MButton'
import { pick } from 'lodash'

interface AccountForm {
  login: string
  password: string
  confirmPassword: string
}

const FormContainer = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '300px',
}))

const ButtonContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
}))

const AccountPage = () => {
  const { me, updateUserData } = useAuthStore()

  const { control, handleSubmit } = useForm({
    values: {
      login: me?.login || '',
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(updateCurrentUserValidation),
    mode: 'all',
  })

  const onSubmit = async (data: AccountForm) => {
    await updateUserData(me!.id, pick(data, ['login', 'password']))
  }

  return (
    <Container>
      <HeaderContainer>
        <MainHeader title="Konto" />
      </HeaderContainer>
      <Grid2>
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="login"
            control={control}
            render={({ field: { onChange, value }, formState: { errors } }) => (
              <MInput
                disabled
                variant="outlined"
                label="Login"
                type="text"
                value={value}
                onChange={onChange}
                helperText={errors.login?.message}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            render={({ field: { onChange, value } }) => (
              <MInput
                variant="outlined"
                label="Nowe hasło"
                type="password"
                value={value}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name="confirmPassword"
            control={control}
            render={({ field: { onChange, value } }) => (
              <MInput
                variant="outlined"
                label="Potwierdź hasło"
                type="password"
                value={value}
                onChange={onChange}
              />
            )}
          />
          <ButtonContainer>
            <MButton
              style={{ width: '250px' }}
              type="submit"
              variant="contained"
            >
              Zaktualizuj ustawienia
            </MButton>
          </ButtonContainer>
        </FormContainer>
      </Grid2>
    </Container>
  )
}

export default AccountPage
