import { create } from 'zustand'
import { GusObject, LoginData } from '../types/auth'
import { callApi } from '../utils/callApi'
import { get as lodashGet } from 'lodash'
import { NotificationService } from '../service/NotificationService'
import { User } from '../types/user'

interface UpdateSettings {
  login: string
  password: string
  printerIpAddress: string
}

interface Store {
  me: User | null
  login: (data: object) => Promise<void>
  logout: () => void
  updateUserData: (id: number, user: object) => Promise<void>
  updateSettings: (settings: object) => Promise<void>
  getDatFromGus: (nip: string) => Promise<GusObject>
}

const setPassword = (settings: object, me: User) => {
  if (
    lodashGet(settings, 'newPassword', '') &&
    lodashGet(settings, 'oldPassword', '') === me.password
  ) {
    return lodashGet(settings, 'newPassword', '')
  } else {
    return me.password
  }
}

const useAuthStore = create<Store>((set, get) => ({
  me: null,

  login: async (data: object) =>
    await callApi<{ data: User }>('/api/login', 'POST', data)
      .then((res) => {
        if (!localStorage.getItem('loginData')) {
          const loginData: LoginData = {
            isLogin: true,
            timestamp: Date.now(),
            login: lodashGet(res.data, 'login', ''),
            password: lodashGet(res.data, 'password', ''),
          }

          localStorage.setItem('loginData', JSON.stringify(loginData))
        }
        set(() => ({ me: res.data }))
      })
      .catch((error) => {
        NotificationService.showNotification(error.message, 'error')
        return error.status
      }),

  logout: () => {
    localStorage.removeItem('loginData')
    set(() => ({ me: null }))
  },

  updateUserData: async (userId: number, user: object) => {
    await callApi<{ message: string }>(`/api/users/${userId}`, 'PUT', user)
      .then((res) => {
        NotificationService.showNotification(res.message, 'success')
        get().logout()
      })
      .catch((e) => {
        NotificationService.showNotification(e.message, 'error')
        throw e
      })
  },

  updateSettings: async (settings: object) => {
    const data: UpdateSettings = {
      login: lodashGet(settings, 'login', ''),
      password: setPassword(settings, get().me as User),
      printerIpAddress: lodashGet(settings, 'printerIpAddress', ''),
    }

    await callApi<{ message: string; isPasswordChanged: boolean }>(
      '/api/settings',
      'PUT',
      data,
    )
      .then((res) => {
        NotificationService.showNotification(res.message, 'success')

        if (res.isPasswordChanged) {
          get().logout()
        }
      })
      .catch((e) => {
        NotificationService.showNotification(e.message, 'error')
        throw e
      })
  },

  getDatFromGus: async (nip) => {
    const res = await callApi<{ data: GusObject }>(
      '/api/get-from-gus',
      'POST',
      { nip: nip },
    )
    return res.data
  },
}))

export default useAuthStore
