import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { isString } from 'lodash'

interface MDialogProps {
  title: string
  content: string | React.JSX.Element
  onConfirm: () => void
  confirmLabel: string
  onCancel?: () => void
  cancelLabel?: string
  disableConfirm?: boolean
}

export interface MDialogHandle {
  changeDialogVisibility: (isOpen: boolean) => void
}

const MDialog = forwardRef<MDialogHandle, MDialogProps>(
  (
    {
      title,
      content,
      onConfirm,
      onCancel,
      confirmLabel,
      cancelLabel,
      disableConfirm,
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)

    const changeDialogVisibility = (isOpen: boolean) => setIsOpen(isOpen)

    const handleCancel = () => {
      if (onCancel) {
        onCancel()
      }
      changeDialogVisibility(false)
    }

    const handleConfirm = () => {
      onConfirm()
      changeDialogVisibility(false)
    }

    useImperativeHandle(ref, () => ({
      changeDialogVisibility,
    }))

    return (
      <Dialog open={isOpen}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {isString(content) ? (
            <DialogContentText>{content}</DialogContentText>
          ) : (
            content
          )}
        </DialogContent>
        <DialogActions>
          {onCancel && cancelLabel && (
            <Button onClick={handleCancel}>{cancelLabel}</Button>
          )}
          <Button disabled={disableConfirm} onClick={handleConfirm}>
            {confirmLabel}
          </Button>
        </DialogActions>
      </Dialog>
    )
  },
)

export default MDialog
