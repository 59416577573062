import { useDroppable } from '@dnd-kit/core'
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable'
import React from 'react'
import DndCard from './DndCard'
import { styled } from '@mui/material'
import { ProductionBoardRecord } from '../../types/production'

const ColumnContainer = styled('div')(() => ({
  borderRadius: '4px',
  padding: '10px',
  overflowY: 'auto',
}))

const ColumnHeader = styled('p')(() => ({
  textAlign: 'left',
  fontWeight: '500',
  color: '#000000',
}))

const CardsContainer = styled('div')(() => ({
  overflowY: 'auto',
}))

interface DnDColumnProps {
  id: string
  label: string
  items: ProductionBoardRecord[]
  type: 'painting' | 'sandblasting'
}

const DnDColumn = ({ id, label, items, type }: DnDColumnProps) => {
  const { setNodeRef } = useDroppable({ id })

  const getColumnStyle = (columnId: string) => {
    const defaults = {
      width:
        type === 'painting'
          ? 'calc(((100% - 2rem) / 5) - 20px)'
          : 'calc(((100% - 2rem) / 3) - 20px)',
    }

    if (columnId === 'done') {
      return {
        ...defaults,
        backgroundColor: '#61bb61',
      }
    } else if (columnId === 'rejected') {
      return {
        ...defaults,
        backgroundColor: '#e97575',
      }
    } else {
      return {
        ...defaults,
        backgroundColor: '#deedf6',
      }
    }
  }

  return (
    <SortableContext id={id} items={items} strategy={rectSortingStrategy}>
      <ColumnContainer style={getColumnStyle(id)} ref={setNodeRef}>
        <ColumnHeader>{label}</ColumnHeader>
        <CardsContainer>
          {items.map((item) => (
            <DndCard key={item.id} item={item} type={type} />
          ))}
        </CardsContainer>
      </ColumnContainer>
    </SortableContext>
  )
}

export default DnDColumn
