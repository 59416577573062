import { create } from 'zustand'
import { Setting } from '../types/settings'
import { callApi } from '../utils/callApi'
import { NotificationService } from '../service/NotificationService'

interface Store {
  settings: Setting | null
  getSettings: () => Promise<void>
  updateSettings: (data: object) => Promise<void>
}

const useSettingsStore = create<Store>((set) => ({
  settings: null,

  getSettings: async () => {
    await callApi<{ data: Setting }>('/api/settings', 'GET').then((res) =>
      set(() => ({ settings: res.data })),
    )
  },

  updateSettings: async (data) => {
    await callApi<{ data: Setting; message: string }>(
      '/api/settings',
      'PUT',
      data,
    )
      .then((res) => {
        NotificationService.showNotification(res.message, 'success')
      })
      .catch((e) => {
        NotificationService.showNotification(e.message, 'error')
        throw e
      })
  },
}))

export default useSettingsStore
