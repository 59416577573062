import React, { useEffect, useRef, useState } from 'react'
import { Container, HeaderContainer, Icon } from '../../styles/styles'
import MainHeader from '../../components/MainHeader'
import useUsersStore from '../../store/useUsersStore'
import MSearchField from '../../components/material/MSearchField'
import { filter, includes, isString, some, toLower } from 'lodash'
import MTable from '../../components/material/MTable'
import { Column } from '../../types/customTableTypes'
import { User } from '../../types/user'
import { Grid2 } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import MDialog, { MDialogHandle } from '../../components/material/MDialog'
import MButton from '../../components/material/MButton'
import AddIcon from '@mui/icons-material/Add'
import { useNavigate } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import { translateUserRole } from '../../utils/helpers'
import useSocket from '../../hooks/useSocket'

interface ExtendUser extends User {
  actions?: string
}

const UsersPage = () => {
  const navigate = useNavigate()
  const { users, getUsers, deleteUser } = useUsersStore()
  const [idToDelete, setIdToDelete] = useState<number | null>(null)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const deleteDialogRef = useRef<MDialogHandle>(null)

  const handleDeleteDialog = (id: number | null) => {
    setIdToDelete(id)
    deleteDialogRef.current?.changeDialogVisibility(true)
  }

  const filteredUsers = (items: User[]) => {
    return filter(items, (item) =>
      some(
        item,
        (value) => isString(value) && includes(toLower(value), toLower(search)),
      ),
    )
  }

  useSocket('user_created', () => getUsers())
  useSocket('user_updated', () => getUsers())
  useSocket('user_deleted', () => getUsers())

  useEffect(() => {
    if (!users.length) {
      setIsLoading(true)
    }
    getUsers().finally(() => {
      setIsLoading(false)
    })
  }, [])

  const columns: Column<ExtendUser>[] = [
    {
      key: 'id',
      name: 'Id',
      width: '50px',
      component: 'th',
      scope: 'row',
    },
    {
      key: 'login',
      name: 'Nazwa',
      width: '30%',
      align: 'left',
    },
    {
      key: 'password',
      name: 'Hasło',
      align: 'left',
    },
    {
      key: 'role',
      name: 'Uprawnienia',
      align: 'left',
      onRender: (item) => <span>{translateUserRole(item.role)}</span>,
    },
    {
      key: 'actions',
      name: 'Actions',
      width: '128px',
      align: 'right',
      onRender: (item) => (
        <Grid2 container direction="row" justifyContent="flex-end">
          <Icon onClick={() => navigate(`/users/edit/${item.id}`)}>
            <EditIcon style={{ color: '#000000' }} />
          </Icon>
          <Icon onClick={() => handleDeleteDialog(item.id)}>
            <DeleteIcon style={{ color: '#000000' }} />
          </Icon>
        </Grid2>
      ),
    },
  ]

  return (
    <Container>
      <HeaderContainer container direction="row" justifyContent="space-between">
        <MainHeader title="Użytkownicy" />
        <Grid2 container direction="row" gap={4}>
          <MButton
            style={{ width: 'fit-content' }}
            startIcon={<AddIcon />}
            variant="contained"
            onClick={() => navigate('/users/add')}
          >
            Dodaj użytkownika
          </MButton>
        </Grid2>
      </HeaderContainer>
      <MSearchField
        sx={{ '&: div': { backgroundColor: 'transparent' } }}
        variant="standard"
        placeholder="Wyszukaj..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <MTable
        columns={columns}
        data={users}
        isLoading={isLoading}
        customDataFilter={filteredUsers}
        style={{ maxHeight: 'calc(100dvh - 184px)', overflow: 'auto' }}
      />
      <MDialog
        ref={deleteDialogRef}
        title="Usuwanie użytkownika"
        content="Czy na pewnon chcesz usunąć tego użytkownika?"
        onConfirm={() => {
          if (idToDelete) deleteUser(idToDelete)
          setIdToDelete(null)
        }}
        onCancel={() => setIdToDelete(null)}
        confirmLabel="Usuń"
        cancelLabel="Anuluj"
      />
    </Container>
  )
}

export default UsersPage
