import React from 'react'
import { Container, HeaderContainer } from '../../styles/styles'
import { Grid2, IconButton, styled, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useNavigate, useParams } from 'react-router-dom'
import RouteGuardDialog from '../../components/RouteGuardDialog'
import { Controller, useForm, UseFormReturn } from 'react-hook-form'
import { User } from '../../types/user'
import useUsersStore from '../../store/useUsersStore'
import { yupResolver } from '@hookform/resolvers/yup'
import { addUserValidation } from '../../utils/validation'
import MInput from '../../components/material/MInput'
import MAutocomplete from '../../components/material/MAutocomplete'
import { userRoleOptions } from '../../utils/selectorsOptions'
import MButton from '../../components/material/MButton'
import { pick } from 'lodash'

const FormContainer = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

const AddUserPage = () => {
  const userId = useParams().userId
  const navigate = useNavigate()
  const { users, addUser, updateUser } = useUsersStore()

  const editedUser = users.find((u) => u.id === Number(userId))

  const methods = useForm({
    defaultValues: {
      login: editedUser?.login || '',
      password: '',
      confirmPassword: '',
      role: editedUser?.role || '',
    },
    resolver: yupResolver(addUserValidation),
    mode: 'all',
  })

  const { control, setValue, formState, handleSubmit, reset } = methods

  return (
    <Container>
      <HeaderContainer
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon style={{ color: '#000000' }} />
        </IconButton>
        <Typography variant="h5">
          {userId ? 'Edytuj użytkownika' : 'Dodaj użytkownika'}
        </Typography>
      </HeaderContainer>
      <FormContainer>
        <Grid2 container direction="row" gap={4}>
          <Grid2 direction="column" container sx={{ flex: 1 }}>
            <Controller
              name="login"
              control={control}
              render={({
                field: { value, onChange },
                formState: { errors },
              }) => (
                <MInput
                  required
                  fullWidth
                  variant="outlined"
                  label="Login"
                  type="text"
                  value={value}
                  onChange={onChange}
                  helperText={errors.login?.message}
                />
              )}
            />
            <Controller
              name="role"
              control={control}
              render={({ field: { value } }) => (
                <MAutocomplete
                  value={value}
                  label="Uprawnienia"
                  onChange={(value: string) =>
                    setValue('role', value, {
                      shouldDirty: true,
                      shouldValidate: true,
                    })
                  }
                  options={userRoleOptions}
                  fullWidth
                />
              )}
            />
          </Grid2>
          <Grid2 direction="column" container sx={{ flex: 1 }}>
            <Controller
              name="password"
              control={control}
              render={({
                field: { value, onChange },
                formState: { errors },
              }) => (
                <MInput
                  required
                  fullWidth
                  variant="outlined"
                  label="Hasło"
                  type="password"
                  value={value}
                  onChange={onChange}
                  helperText={errors.password?.message}
                />
              )}
            />
            <Controller
              name="confirmPassword"
              control={control}
              render={({
                field: { value, onChange },
                formState: { errors },
              }) => (
                <MInput
                  required
                  fullWidth
                  variant="outlined"
                  label="Potwierdź hasło"
                  type="password"
                  value={value}
                  onChange={onChange}
                  helperText={errors.confirmPassword?.message}
                />
              )}
            />
          </Grid2>
        </Grid2>
        <Grid2
          style={{ position: 'absolute', bottom: '1rem', right: '1rem' }}
          container
          direction="row"
          justifyContent="flex-end"
        >
          <MButton
            disabled={!formState.isValid}
            variant="contained"
            onClick={handleSubmit(async (values) => {
              if (userId) {
                const statusCode = await updateUser(
                  editedUser!.id,
                  pick(values, ['login', 'password', 'role']),
                )

                if (statusCode === 200) {
                  reset()
                  navigate(-1)
                }
              } else {
                const statusCode = await addUser(
                  pick(values, ['login', 'password', 'role']),
                )

                if (statusCode === 200) {
                  reset()
                  navigate(-1)
                }
              }
            })}
          >
            Zapisz
          </MButton>
        </Grid2>
      </FormContainer>
      <RouteGuardDialog methods={methods as unknown as UseFormReturn<User>} />
    </Container>
  )
}

export default AddUserPage
