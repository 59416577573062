import React, { useEffect, useRef, useState } from 'react'
import { Grid2 } from '@mui/material'
import useDocumentsStore from '../../store/useDocumentsStore'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DeleteIcon from '@mui/icons-material/Delete'
import 'react-pdf/dist/Page/TextLayer.css'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import PdfPreview from '../../components/PdfPreview'
import { removeExtensionFromFileName } from '../../utils/helpers'
import { filter, includes, isString, some, toLower } from 'lodash'
import PrintIcon from '@mui/icons-material/Print'
import MSearchField from '../../components/material/MSearchField'
import { Container, HeaderContainer, Icon } from '../../styles/styles'
import MainHeader from '../../components/MainHeader'
import useSocket from '../../hooks/useSocket'
import MDialog, { MDialogHandle } from '../../components/material/MDialog'
import { Column } from '../../types/customTableTypes'
import { Document } from '../../types/document'
import MTable from '../../components/material/MTable'

interface ExtendDocument extends Document {
  actions?: string
}

const DocumentsPage = () => {
  const [search, setSearch] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [documentUrl, setDocumentUrl] = useState<string | null>(null)
  const [idToDelete, setIdToDelete] = useState<number | null>(null)

  const dialogRef = useRef<MDialogHandle>(null)

  const { documents, getDocuments, deleteDocument, printDocument } =
    useDocumentsStore()

  useSocket('document_created', () => getDocuments())
  useSocket('document_deleted', () => getDocuments())

  const handleClose = () => {
    setOpen(false)
    setDocumentUrl('')
  }

  const handleOpenPreview = (url: string) => {
    setOpen(true)
    setDocumentUrl(url)
  }

  const handleOpenDialog = (id: number | null) => {
    setIdToDelete(id)
    dialogRef.current?.changeDialogVisibility(true)
  }

  const filteredDocuments = (items: Document[]) => {
    return filter(items, (item) =>
      some(
        item,
        (value) => isString(value) && includes(toLower(value), toLower(search)),
      ),
    )
  }

  useEffect(() => {
    if (!documents.length) {
      setIsLoading(true)
    }
    getDocuments().finally(() => {
      setIsLoading(false)
    })
  }, [])

  const columns: Column<ExtendDocument>[] = [
    {
      key: 'id',
      name: 'Id',
      width: '50px',
      component: 'th',
      scope: 'row',
    },
    {
      key: 'documentName',
      name: 'Dokument',
      width: 'calc(100dvw - 166px)',
      align: 'left',
      onRender: (item) => (
        <span>{removeExtensionFromFileName(item.documentName)}</span>
      ),
    },
    {
      key: 'actions',
      name: 'Actions',
      width: '128px',
      align: 'right',
      onRender: (item) => (
        <Grid2 container direction="row" justifyContent="flex-end">
          <Icon onClick={() => printDocument(item.id)}>
            <PrintIcon style={{ color: '#000000' }} />
          </Icon>
          <Icon onClick={() => handleOpenPreview(item.path)}>
            <VisibilityIcon style={{ color: '#000000' }} />
          </Icon>
          <Icon onClick={() => handleOpenDialog(item.id)}>
            <DeleteIcon style={{ color: '#000000' }} />
          </Icon>
        </Grid2>
      ),
    },
  ]

  return (
    <Container>
      <HeaderContainer container direction="row" justifyContent="space-between">
        <MainHeader title="Documenty" />
      </HeaderContainer>
      <MSearchField
        sx={{ '&: div': { backgroundColor: 'transparent' } }}
        variant="standard"
        placeholder="Wyszukaj..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <MTable
        columns={columns}
        data={documents}
        isLoading={isLoading}
        customDataFilter={filteredDocuments}
        style={{ maxHeight: 'calc(100dvh - 184px)', overflow: 'auto' }}
      />
      <PdfPreview url={documentUrl} open={open} handleClose={handleClose} />
      <MDialog
        ref={dialogRef}
        title="Usuwanie dokumentu"
        content="Czy na pewno chcesz usunąć ten dokument?"
        onConfirm={() => {
          if (idToDelete) deleteDocument(idToDelete)
          setIdToDelete(null)
        }}
        onCancel={() => setIdToDelete(null)}
        cancelLabel="Anuluj"
        confirmLabel="Usuń"
      />
    </Container>
  )
}

export default DocumentsPage
