import { create } from 'zustand'

interface Store {
  isOpen: boolean
  setIsOpen: () => void
}

const useAppStore = create<Store>((set, get) => ({
  isOpen: true,
  setIsOpen: () => {
    set({ isOpen: !get().isOpen })
  },
}))

export default useAppStore
